<template>
  <v-app-bar app clipped-right color="#000000">
    <v-img
      src="@/assets/images/Logo.png"
      height="8vh"
      width="8vh"
      contain
      :max-width="!$vuetify.breakpoint.mobile ? '8vh' : ''"
      :position="$vuetify.breakpoint.mobile ? 'center center' : ''"
      style="cursor: pointer;"
    />
    <p
      style="color: #ffffff;"
      class="ma-4 font-weight-black text-subtitle-1"
      v-if="!$vuetify.breakpoint.mobile"
    >
      Bernardo Bernal | Desarrollador Web
    </p>
    <v-spacer v-if="!$vuetify.breakpoint.mobile" />
    <v-app-bar-nav-icon color="white" @click="drawer" />
  </v-app-bar>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "AppBar",
  methods: {
    ...mapMutations({
      drawer: "changeDrawer"
    })
  }
};
</script>
